<template>
  <div class="p-4 pt-24">

    <div class="hidden sm:block" aria-hidden="true">
      <div class="py-5">
        <div class="border-t border-gray-200" />
      </div>
    </div>

    <div class="mt-10 sm:mt-0" v-if="userCopy">
      <div class="md:grid md:grid-cols-3 md:gap-6">
        <div class="md:col-span-1">
          <div class="px-4 sm:px-0">
            <h3 class="text-lg font-medium leading-6 text-gray-900">Personal Information</h3>
            <p class="mt-1 text-sm text-gray-600">
              Use your personal email
            </p>
          </div>
        </div>
        <div class="mt-5 md:mt-0 md:col-span-2">
          <div>
            <div class="shadow overflow-hidden sm:rounded-md">
              <div class="px-4 py-5 bg-white sm:p-6">
                <div class="grid grid-cols-6 gap-6">
                  <div class="col-span-6 sm:col-span-3">
                    <label for="first-name" class="block text-sm font-medium text-gray-700">First name</label>
                    <input v-model="first_name" type="text" name="first-name" id="first-name" autocomplete="given-name" class="mt-1   block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
                  </div>

                  <div class="col-span-6 sm:col-span-3">
                    <label for="last-name" class="block text-sm font-medium text-gray-700">Last name</label>
                    <input v-model="last_name" type="text" name="last-name" id="last-name" autocomplete="family-name" class="mt-1   block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
                  </div>

                  <div class="col-span-6 sm:col-span-4">
                    <label for="email-address" class="block text-sm font-medium text-gray-700">Email address</label>
                    <input v-model="email" type="text" name="email-address" id="email-address" autocomplete="email" class="mt-1   block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
                  </div>

                  <div class="col-span-6 sm:col-span-3">
                    <label for="last-name" class="block text-sm font-medium text-gray-700">Birth place</label>
                    <input v-model="birth_place" type="text" class="mt-1   block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" />
                  </div>

                  <div class="col-span-6 sm:col-span-3">
                    <label for="last-name" class="block text-sm font-medium text-gray-700">Birthday</label>
                    <Datepicker
                        class="mt-1"
                        v-model="birthday"
                        :enableTimePicker="false"
                        placeholder="Birthday"
                        :format="format"
                    />
                  </div>

                </div>
              </div>
              <div class="px-4 py-3 bg-gray-50 text-right sm:px-6">
                <button @click="savePersonal" class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 ">
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="hidden sm:block" aria-hidden="true">
      <div class="py-5">
        <div class="border-t border-gray-200" />
      </div>
    </div>

    <div class="mt-10 sm:mt-0" v-if="userCopy">
      <div class="md:grid md:grid-cols-3 md:gap-6">
        <div class="md:col-span-1">
          <div class="px-4 sm:px-0">
            <h3 class="text-lg font-medium leading-6 text-gray-900">Notifications</h3>
            <p class="mt-1 text-sm text-gray-600">
              Decide which communications you'd like to receive and how.
            </p>
          </div>
        </div>
        <div class="mt-5 md:mt-0 md:col-span-2">
          <div>
            <div class="shadow overflow-hidden sm:rounded-md">
              <div class="px-4 py-5 bg-white space-y-6 sm:p-6">
                <fieldset>
                  <legend class="text-base font-medium text-gray-900">By Email</legend>
                  <div class="mt-4 space-y-4">
                    <div class="flex items-start">
                      <div class="flex items-center h-5">
                        <input v-model="certificate" id="comments" name="comments" type="checkbox" class=" h-4 w-4 text-blue-600 border-gray-300 rounded" />
                      </div>
                      <div class="ml-3 text-sm">
                        <label for="comments" class="font-medium text-gray-700">Certificates</label>
                        <p class="text-gray-500">Receive your certificates on email.</p>
                      </div>
                    </div>
                    <div class="flex items-start">
                      <div class="flex items-center h-5">
                        <input v-model="expiration" id="candidates" name="candidates" type="checkbox" class=" h-4 w-4 text-blue-600 border-gray-300 rounded" />
                      </div>
                      <div class="ml-3 text-sm">
                        <label for="candidates" class="font-medium text-gray-700">Expiration</label>
                        <p class="text-gray-500">Get notified when your license is close to expire.</p>
                      </div>
                    </div>
                    <div class="flex items-start">
                      <div class="flex items-center h-5">
                        <input v-model="offers" id="offers" name="offers" type="checkbox" class=" h-4 w-4 text-blue-600 border-gray-300 rounded" />
                      </div>
                      <div class="ml-3 text-sm">
                        <label for="offers" class="font-medium text-gray-700">Offers</label>
                        <p class="text-gray-500">Get notified when we have new products.</p>
                      </div>
                    </div>
                  </div>
                </fieldset>
              </div>
              <div class="px-4 py-3 bg-gray-50 text-right sm:px-6">
                <button @click="saveNotifications" type="submit" class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 ">
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <router-link to="/certificates" class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 ">
      Certificates
    </router-link>
    <success-notification :show="success" message="User is updated"></success-notification>
    <error-notification :message="error" :show="!!error" />
  </div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import successNotification from "../components/ui/SuccessNotification";
import errorNotification from "@/components/ui/ErrorNotification";

export default {
  components: {
    successNotification,
    errorNotification
  },
  setup() {
    const format = (date) => {
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();

      return `${day}-${month}-${year}`;
    }

    return {format}
  },
  data() {
    return {
      user_obj: {},
      success: false,
      error: false
    }
  },
  methods: {
    ...mapMutations('auth', ['editCopyUser']),
    async savePersonal(){
      try {
        await this.axios.patch(`v2/me/`, {
          email: this.email,
          first_name: this.first_name,
          last_name: this.last_name,
          birthday: this.birthday,
          birth_place: this.birth_place
        })
        this.success = true;
        this.$store.dispatch('auth/fetchUser')
        setTimeout(() => {
          this.success = false;
        }, 3000)
      } catch (e) {
        this.loading = false
        let o = '';
        for (const oKey in e.response.data) {
          o += ` Field ${oKey} has errors: ${e.response.data[oKey].join(',')}`
        }
        this.error = o
        this.openNewModal = true;
        setTimeout(
            ()=>{
              this.error = null
            }, 5000
        )
      }
    },
    async saveNotifications() {
      await this.axios.patch(`v2/me/`, {
        notifications_settings: {
          ...this.userCopy.notifications_settings
        }
      })
      this.success = true;
      setTimeout(() => {
        this.success = false;
      }, 3000)
    }
  },
  computed: {
    ...mapGetters('auth', ['userCopy']),
    first_name: {
      get() {
        return this.userCopy.first_name
      },
      set(value) {
        this.editCopyUser({first_name: value})
      }
    },
    birth_place: {
      get() {
        return this.userCopy.birth_place
      },
      set(value) {
        this.editCopyUser({birth_place: value})
      }
    },
    birthday: {
      get() {
        try {
          if (!this.userCopy.birthday) {
            return null
          }

          if (this.userCopy.birthday instanceof Date) {
            return this.userCopy.birthday
          }
          console.log(this.userCopy.birthday)
          let dd = this.userCopy.birthday.split("-")
          return `${dd[2]}-${dd[1]}-${dd[0]}`
        } catch (e) {
          return null
        }
      },
      set(value) {
        this.editCopyUser({birthday: this.format(value)})
      }
    },
    last_name: {
      get() {
        return this.userCopy.last_name
      },
      set(value) {
        this.editCopyUser({last_name: value})
      }
    },
    email: {
      get() {
        return this.userCopy.email
      },
      set(value) {
        this.editCopyUser({email: value})
      }
    },
    certificate: {
      get() {
        return this.userCopy.notifications_settings['certificate']
      },
      set(value) {
        this.editCopyUser({notifications_settings: {...this.userCopy.notifications_settings, certificate: value}})
      }
    },
    offers: {
      get() {
        return this.userCopy.notifications_settings['offers']
      },
      set(value) {
        this.editCopyUser({notifications_settings: {...this.userCopy.notifications_settings, offers: value}})
      }
    },
    expiration: {
      get() {
        return this.userCopy.notifications_settings['expiration']
      },
      set(value) {
        this.editCopyUser({notifications_settings: {...this.userCopy.notifications_settings, expiration: value}})
      }
    },
  },
}
</script>